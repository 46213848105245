import { Maniless } from '@wsb/guac-widget-core';

const {
  constants: { routes }
} = Maniless;

export default {
  ...routes,
  SOCIAL_SHARING: '/socialSharing',
  SERVICES_DETAILS: '/serviceDetails/$'
};
