import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import FormattersActionTypes from '../constants/actionTypes/Formatters';
import { Translate as translate } from '@wsb/vnext-widget-content-translate';
import numberFormatterMapper from '../utils/numberFormatterMapper';
import getDateTimeFormatterI18n from '../utils/getDateTimeFormatterI18n';
import DateTimeFormatter from '@wsb/commerce-date-time-formatter';
import NumberFormatter from '@wsb/commerce-number-formatter';
import PhoneNumberFormatter from '@wsb/commerce-phone-number-formatter';

class FormattersStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      isFormattersSet: false,
      numberFormatter: new NumberFormatter(),
      dateTimeFormatter: new DateTimeFormatter(),
      phoneNumberFormatter: new PhoneNumberFormatter()
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;

      switch (action.type) {
        case FormattersActionTypes.SET_FORMATTERS: {
          const { data } = action;

          return this.setState({
            isFormattersSet: true,
            numberFormatter: new NumberFormatter(numberFormatterMapper(data.currencyFormat)),
            dateTimeFormatter: new DateTimeFormatter({
              timeZone: data.timeZone,
              dateFormat: data.dateFormat,
              timeFormat: data.timeFormat,
              i18n: getDateTimeFormatterI18n({
                i18nize: translate(data.staticContent),
                timeZones: Object.keys(data.staticContent.timeZones)
              })
            }),
            phoneNumberFormatter: new PhoneNumberFormatter({ countryIso: data.country })
          });
        }
        default:
          break;
      }
    });
  }
}

export default new FormattersStore();
