import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import BookingFormActionTypes from '../constants/actionTypes/BookingForm';
import RouterActionTypes from '../constants/actionTypes/Router';
import ConfigActionTypes from '../constants/actionTypes/Config';

class BookingFormStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      isFetching: false,
      isFetchingRavenContact: false,
      error: null,
      deepLinkError: null,
      paypalError: null,
      client: { phone_number: '' },
      booking: {},
      location: {},
      membership: null,
      marketing: false,
      answers: [],
      isC2LoggedIn: false,
      isRavenContactLoaded: false
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  /* eslint-disable complexity */
  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;

      switch (action.type) {
        case BookingFormActionTypes.UPDATE_CLIENT:
          return this.setState({
            client: data
          });
        case BookingFormActionTypes.UPDATE_LOCATION:
          return this.setState({
            location: data
          });
        case BookingFormActionTypes.UPDATE_ANSWERS:
          return this.setState({
            answers: data
          });
        case BookingFormActionTypes.UPDATE_MEMBERSHIP:
          return this.setState({
            membership: data
          });
        case BookingFormActionTypes.UPDATE_MARKETING:
          return this.setState({
            marketing: data
          });
        case BookingFormActionTypes.REQUEST_SAVE_BOOKING:
        case BookingFormActionTypes.START_PAYMENT:
          return this.setState({
            isFetching: true,
            error: null,
            paypalError: null
          });
        case BookingFormActionTypes.RECEIVE_BOOKING:
          return this.setState({
            isFetching: false,
            error: null,
            paypalError: null,
            booking: data
          });
        case BookingFormActionTypes.ERROR_RECEIVE_BOOKING:
          return this.setState({
            isFetching: false,
            error: data
          });
        case RouterActionTypes.REQUEST_DEEP_LINK_STATUS:
          return this.setState({
            isFetching: true,
            deepLinkError: null
          });
        case RouterActionTypes.RECEIVE_DEEP_LINK_STATUS:
          return this.setState({
            isFetching: false,
            deepLinkError: null
          });
        case RouterActionTypes.RECEIVE_DEEP_LINK_ERROR:
          return this.setState({
            isFetching: false,
            deepLinkError: data
          });
        case BookingFormActionTypes.CLEAR_ERRORS:
          return this.setState({
            error: null,
            paypalError: null,
            deepLinkError: null
          });
        case BookingFormActionTypes.RECEIVE_PAYPAL_ERROR:
          return this.setState({
            isFetching: false,
            paypalError: data
          });
        case BookingFormActionTypes.CANCEL_PAYMENT:
          return this.setState({
            isFetching: false,
            error: null,
            paypalError: null
          });
        case BookingFormActionTypes.REQUEST_ACTIVE_PAYMENT_METHODS:
          return this.setState({
            isFetchingPaymentMethods: true,
            activePaymentMethods: null
          });
        case BookingFormActionTypes.RECEIVE_ACTIVE_PAYMENT_METHODS:
          return this.setState({
            isFetchingPaymentMethods: false,
            activePaymentMethods: data
          });
        case BookingFormActionTypes.ERROR_RECEIVE_ACTIVE_PAYMENT_METHODS:
          return this.setState({
            isFetchingPaymentMethods: false,
            error: data
          });
        case ConfigActionTypes.REQUEST_COUNTRY_STATES:
          return this.setState({
            isFetching: true,
            error: null
          });
        case ConfigActionTypes.RECEIVE_COUNTRY_STATES:
          return this.setState({
            isFetching: false
          });
        case ConfigActionTypes.ERROR_RECEIVE_COUNTRY_STATES:
          return this.setState({
            isFetching: false,
            error: data
          });
        case BookingFormActionTypes.REQUEST_RAVEN_CONTACT:
          return this.setState({
            isFetchingRavenContact: true
          });
        case BookingFormActionTypes.RECEIVE_RAVEN_CONTACT:
          return this.setState({
            isRavenContactLoaded: true,
            isFetchingRavenContact: false,
            isC2LoggedIn: true
          });
        case BookingFormActionTypes.SET_NO_RAVEN_CONTACT:
          return this.setState({
            isRavenContactLoaded: true,
            isFetchingRavenContact: false,
            isC2LoggedIn: false
          });
        default:
          return;
      }
    });
  }
  /* eslint-enable complexity */
}

export default new BookingFormStore();
