import keyMirror from 'keymirror';

export default keyMirror({
  REQUEST_CONFIG: null,
  RECEIVE_CONFIG: null,
  ERROR_RECEIVE_CONFIG: null,
  REQUEST_COUNTRY_STATES: null,
  RECEIVE_COUNTRY_STATES: null,
  ERROR_RECEIVE_COUNTRY_STATES: null,
  SET_CONFIG: null,
  REQUEST_PROVISION_ACCOUNT: null,
  SUCCESS_PROVISION_ACCOUNT: null,
  ERROR_PROVISION_ACCOUNT: null,
  SET_CLS_HANDLER_RENDERED: null,
  UPDATE_RENDER_MODE: null,
  UPDATE_WEBSITE_ID: null
});
