import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AvailableTimeSelectionActionTypes from '../constants/actionTypes/AvailableTimeSelection';

class AvailableTimeSelectionStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      selectedAvailableTime: null,
      selectedResource: null,
      currentViewDevice: null
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data, viewDevice } = action;

      switch (action.type) {
        case AvailableTimeSelectionActionTypes.SELECT_RESOURCE:
          return this.setState({
            selectedResource: data
          });
        case AvailableTimeSelectionActionTypes.SELECT_AVAILABLE_TIME:
          return this.setState({
            selectedAvailableTime: data,
            currentViewDevice: viewDevice
          });
        default:
          return;
      }
    });
  }
}

export default new AvailableTimeSelectionStore();
