import serviceRecurrenceTypes from '../constants/recurrenceTypes';

export const isRecurringEvent = recurrenceType => {
  return recurrenceType === serviceRecurrenceTypes.RECURRING;
};

export const isSingleEvent = recurrenceType => {
  return recurrenceType === serviceRecurrenceTypes.NONE;
};

export const isEvent = recurrenceType => {
  if (isRecurringEvent(recurrenceType)) return true;
  if (isSingleEvent(recurrenceType)) return true;
  return false;
};

export const isAppointment = recurrenceType => {
  return recurrenceType === serviceRecurrenceTypes.BUSINESS_HOURS;
};

export const getServiceType = recurrenceType => {
  const typeMap = {
    [serviceRecurrenceTypes.BUSINESS_HOURS]: 'APPOINTMENT',
    [serviceRecurrenceTypes.NONE]: 'SINGLE_EVENT',
    [serviceRecurrenceTypes.RECURRING]: 'RECURRING_EVENT'
  };

  return typeMap[recurrenceType];
};
