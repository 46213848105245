import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { UX2, Maniless } from '@wsb/guac-widget-core';
import dataAids from '../constants/dataAids';
import renderModes from '../constants/renderModes';
import * as MetricsEvents from '../constants/metrics';
import routes from '../../constants/routes';
import { trackC1 } from '../utils/trackMetric';
import iconPack from './IconsPack';
import { HIVEMIND_ON } from '../../constants/hivemind';

const styles = {
  modalBackground: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    paddingHorizontal: 'small'
  },
  text: {
    fontFamily: 'gdsherpa, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    color: 'white',
    textAlign: 'left',
    marginBottom: 0
  },
  learnMoreContainer: {
    marginBottom: '18px'
  },
  learnMore: {
    color: '#61EDEA !important',
    fontFamily: 'gdsherpa, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 850,
    lineHeight: '20px'
  },
  benefitsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 12,
    marginBottom: '28px',
    '@md': {
      gridTemplateColumns: '1fr 1fr',
      width: '600px'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    flexShrink: 0,
    marginRight: '14px'
  }
};

const benefits = {
  'en-US': [
    {
      icon: 'clock',
      text: 'allowCustomersBookAndPayServices'
    },
    {
      icon: 'users3',
      text: 'offerOneTimeOrRecurringAppointments'
    },
    {
      icon: 'share',
      text: 'letCustomersBook'
    },
    {
      icon: 'calendar',
      text: 'manageMultipleCalendars'
    },
    {
      icon: 'send',
      text: 'sendEmailAndTextReminders'
    },
    {
      icon: 'createBtn',
      text: 'letCustomersSeamlesslyReschedule'
    }
  ],
  nonEnUs: [
    {
      icon: 'clock',
      text: 'allowCustomersBook'
    },
    {
      icon: 'users3',
      text: 'offerOneTimeOrRecurringAppointments'
    },
    {
      icon: 'website',
      text: 'addBeautifullyDesignedAppointment'
    },
    {
      icon: 'mailOpen',
      text: 'sendEmailReminders'
    },
    {
      icon: 'createBtn',
      text: 'letCustomersSeamlesslyReschedule'
    },
    {
      icon: 'megaphone',
      text: 'boostBookings'
    }
  ]
};

const Benefits = ({ staticContent, locale }) => {
  const {
    Element: { Block, MoreLink, Icon }
  } = UX2;
  const benefitsExperiment = benefits;
  const benefitsToShow = benefitsExperiment[locale] || benefitsExperiment.nonEnUs;
  const [expanded, setExpanded] = useState(false);

  return (
    <Block>
      { !expanded && (
        <Block style={ styles.learnMoreContainer }>
          <MoreLink
            style={ styles.learnMore }
            onClick={ () => {
              trackC1(MetricsEvents.APPOINTMENTS_ZERO_STATE_LEARN_MORE_CTA_CLICKED);
              setExpanded(!expanded);
            } }
          >
            { staticContent.learnMore }
            <Icon iconPack={ iconPack } icon='arrowUp' rotate={ 180 } />
          </MoreLink>
        </Block>
      ) }
      { expanded && (
        <Block style={ styles.benefitsContainer }>
          { benefitsToShow.map(benefit => (
            <Block key={ benefit.text } style={ styles.item }>
              <Icon style={ styles.icon } iconPack={ iconPack } icon={ benefit.icon } />
              <p style={ styles.text }>{ staticContent[benefit.text] }</p>
            </Block>
          )) }
        </Block>
      ) }
      <p style={{ ...styles.text, margin: 0, textAlign: 'center' }}>
        { staticContent.emptyServiceListDescription }
      </p>
    </Block>
  );
};

const EmptyServiceListModal = ({
  widgetId,
  ctaUrl,
  staticContent,
  locale,
  olaBenefitsZeroStateExperiment,
  isAddServicesMutatorExperiment,
  experimentReleaseDate,
  websiteCreatedDate
}) => {
  const {
    Element: { Block },
    Component: { ZeroStateOverlay }
  } = UX2;
  const {
    context: { Router }
  } = Maniless;

  useEffect(() => {
    trackC1(MetricsEvents.APPOINTMENTS_ZERO_STATE_IMPRESSION);
    trackC1(
      olaBenefitsZeroStateExperiment === HIVEMIND_ON
        ? MetricsEvents.APPOINTMENTS_ZERO_STATE_BENEFITS_VARIANT_IMPRESSION
        : MetricsEvents.APPOINTMENTS_ZERO_STATE_BENEFITS_CONTROL_IMPRESSION
    );
    if (isAddServicesMutatorExperiment && websiteCreatedDate >= experimentReleaseDate) {
      trackC1(MetricsEvents.SERVICE_DETAILS_VARIANT_IMPRESSION);
    } else {
      trackC1(MetricsEvents.SERVICE_DETAILS_CONTROL_IMPRESSION);
    }
  }, [olaBenefitsZeroStateExperiment]);

  const openUrl = (event, router) => {
    event.stopPropagation();
    trackC1(MetricsEvents.APPOINTMENTS_ZERO_STATE_ADD_SERVICES_CTA_CLICKED);

    if (isAddServicesMutatorExperiment && websiteCreatedDate >= experimentReleaseDate) {
      router.updateRoute({ widget: widgetId, route: routes.SERVICES_DETAILS });
    } else {
      window.open(ctaUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Router.Consumer>
      { router => (
        <Block style={ styles.modalBackground } data-aid={ dataAids.EMPTY_CONTAINER_RENDERED }>
          <ZeroStateOverlay
            renderMode={ renderModes.EDIT }
            title={ staticContent.emptyServiceListTitle }
            description={
              olaBenefitsZeroStateExperiment === HIVEMIND_ON ? (
                <Benefits staticContent={ staticContent } locale={ locale } />
              ) : (
                staticContent.emptyServiceListDescription
              )
            }
            button={{
              onClick: event => openUrl(event, router),
              children: staticContent.emptyServiceListCallToAction
            }}
          />
        </Block>
      ) }
    </Router.Consumer>
  );
};

EmptyServiceListModal.propTypes = {
  ctaUrl: PropTypes.string,
  category: PropTypes.string,
  staticContent: PropTypes.object.isRequired,
  isAddServicesMutatorExperiment: PropTypes.bool,
  experimentReleaseDate: PropTypes.string,
  websiteCreatedDate: PropTypes.string
};

export default EmptyServiceListModal;
