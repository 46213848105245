import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import dataAids from '../constants/dataAids';

const styles = {
  container: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    zIndex: 200
  },
  loader: {
    fontSize: 'xxlarge',
    opacity: 0.5
  }
};

export class Fetching extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fetching } = this.props;
    const { Block, Loader } = UX2.Element;

    return fetching ? (
      <Block style={ styles.container }>
        <Loader
          data-aid={ dataAids.FETCHING_LOADER_RENDERED }
          display='block'
          fontColor='lowContrast'
          style={ styles.loader }
        />
      </Block>
    ) : null;
  }
}

Fetching.propTypes = {
  fetching: PropTypes.bool
};

export default Fetching;
