import {
  SERVICE_PARAM,
  AVAILABLE_TIME_PARAM,
  RESOURCE_PARAM,
  FRIENDLY_SERVICE_URL_PATH,
  BLOG_URL_PATH
} from '../constants/router';

const RouterHelper = {
  getSearchParam(name) {
    return new URLSearchParams(window.location.search).get(name);
  },

  getPagePath() {
    const url = new URL(window.location.href);

    const deepLinkPosition = url.pathname.indexOf(`/${FRIENDLY_SERVICE_URL_PATH}/`);
    if (deepLinkPosition > -1) {
      url.pathname = url.pathname.substr(0, deepLinkPosition);
    }

    // Ensures other widgets' search params are kept.
    url.searchParams.delete(SERVICE_PARAM);
    url.searchParams.delete(AVAILABLE_TIME_PARAM);
    url.searchParams.delete(RESOURCE_PARAM);

    return url.toString();
  },

  buildServicePath(slug, currentRoute) {
    const newUrl = new URL(window.location.href);

    if (currentRoute) {
      newUrl.pathname = `${currentRoute}/${FRIENDLY_SERVICE_URL_PATH}/${slug}`.replace(
        /\/{2,}/g,
        '/'
      );
    } else {
      // Safety code in case PageStore does not exist
      for (const path of [FRIENDLY_SERVICE_URL_PATH, BLOG_URL_PATH]) {
        const deepLinkPosition = newUrl.pathname.indexOf(`/${path}/`);
        if (deepLinkPosition > -1) {
          newUrl.pathname = newUrl.pathname.substr(0, deepLinkPosition);
          break;
        }
      }

      newUrl.pathname = `${newUrl.pathname}/${FRIENDLY_SERVICE_URL_PATH}/${slug}`.replace(
        /\/{2,}/g,
        '/'
      );
    }

    // Ensures other widgets' search params are kept.
    newUrl.searchParams.delete(SERVICE_PARAM);
    newUrl.searchParams.delete(AVAILABLE_TIME_PARAM);
    newUrl.searchParams.delete(RESOURCE_PARAM);

    return newUrl.toString();
  },

  isDeepLink() {
    return (
      typeof window !== 'undefined' &&
      window.location.pathname.includes(`/${FRIENDLY_SERVICE_URL_PATH}/`)
    );
  },

  getFriendlySlug() {
    const { pathname } = window.location;

    return (
      this.isDeepLink() &&
      pathname.substr(
        pathname.indexOf(`/${FRIENDLY_SERVICE_URL_PATH}/`) + `/${FRIENDLY_SERVICE_URL_PATH}/`.length
      )
    );
  },

  updateSearchParam(param, value, url = window.location.href) {
    const parsedUrl = new URL(url);

    if (!value) {
      parsedUrl.searchParams.delete(param);
    } else {
      parsedUrl.searchParams.set(param, value);
    }

    return parsedUrl.toString();
  },

  removeSearchParam(param, url = window.location.href) {
    const parsedUrl = new URL(url);

    parsedUrl.searchParams.delete(param);

    return parsedUrl.toString();
  }
};

export default RouterHelper;
