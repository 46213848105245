import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import CartProvider from './CartProvider';
import { ManilessProvider } from './ManilessProvider';
import ServiceListStore from '../stores/ServiceList';
import ConfigStore from '../stores/Config';
import FormattersStore from '../stores/Formatters';
import {
  fetchConfig,
  setConfig,
  updateRenderMode,
  updateWebsiteId,
  reportWidgetAdded
} from '../actions/Config';
import { verifyServicesExistence } from '../actions/ServiceList';
import EmptyServiceListModal from '../components/EmptyServiceListModal';
import { setFormatters } from '../actions/Formatters';
import AppointmentsRouter from './Router';
import renderModes from '../constants/renderModes';
import polyfillZones from '@wsb/commerce-polyfill-timezones';
import { trackMetric } from '../utils/trackMetric';
import {
  NEW_CART_EXPERIMENT_CONTROL_IMPRESSION,
  NEW_CART_EXPERIMENT_VARIANT_IMPRESSION
} from '../constants/metrics';
import {HIVEMIND_ON} from "../../constants/hivemind";

export default class AppointmentsSection extends Component {
  static get propTypes() {
    return {
      renderMode: PropTypes.string,
      viewDevice: PropTypes.string,
      category: PropTypes.string,
      section: PropTypes.string,
      featureFlags: PropTypes.object,
      staticContent: PropTypes.object,
      currentPageRoute: PropTypes.string,
      websiteId: PropTypes.string,
      callToActionText: PropTypes.string,
      imageCropMethod: PropTypes.string,
      commerceStoreUrl: PropTypes.string,
      olaSiteBaseUrl: PropTypes.string,
      stockImages: PropTypes.array,
      olaBenefitsZeroStateExperiment: PropTypes.string
    };
  }

  constructor(props) {
    super(props);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.state = this.getStoreState();
  }

  getStoreState() {
    const serviceListState = ServiceListStore.getState();
    const configState = ConfigStore.getState();
    const { isFormattersSet } = FormattersStore.getState();

    return {
      ...serviceListState,
      ...configState,
      isFormattersSet
    };
  }

  loadConfig() {
    return fetchConfig().then(({ isSuccess } = {}) => {
      if (!isSuccess) return;

      const { staticContent, renderMode } = this.props;

      const {
        isServiceExistenceSet,
        timeZoneName,
        currency_format: currencyFormat,
        date_format: dateFormat,
        time_format: timeFormat,
        business_country: businessCountry,
        areServicesHidden
      } = this.state;

      if (
        !areServicesHidden &&
        !isServiceExistenceSet &&
        (renderMode === renderModes.EDIT || renderMode === renderModes.DISPLAY)
      ) {
        verifyServicesExistence();
      }

      if (renderMode === renderModes.PUBLISH) {
        this.trackCartExperimentImpression();
      }

      polyfillZones(() => {
        setFormatters({
          timeZone: timeZoneName,
          currencyFormat,
          dateFormat,
          timeFormat,
          staticContent,
          country: businessCountry ? businessCountry.iso : null
        });
      });
    });
  }

  willReportWidgetAdded(setupStatus) {
    const { renderMode } = this.props;

    if (setupStatus === 'complete') return false;
    return [renderModes.EDIT, renderModes.PREVIEW].includes(renderMode);
  }

  componentDidMount() {
    const { renderMode } = this.props;

    ServiceListStore.addListener('change', this.onStoreChange);
    ConfigStore.addListener('change', this.onStoreChange);

    updateRenderMode(renderMode);
    setConfig(this.props);

    this.loadConfig().then(() => {
      const setupStatus = this.state.setup_status;

      if (this.willReportWidgetAdded(setupStatus)) {
        reportWidgetAdded();
      }
    });
  }

  trackCartExperimentImpression = () => {
    const treatment = ConfigStore.getExperimentTreatment('gopay');
    if (!treatment) return;

    const eventKey =
      treatment === 'variant'
        ? NEW_CART_EXPERIMENT_VARIANT_IMPRESSION
        : NEW_CART_EXPERIMENT_CONTROL_IMPRESSION;

    trackMetric(eventKey);
  };

  componentDidUpdate(prevProps) {
    const { renderMode, websiteId } = this.props;
    const { isServiceExistenceSet, isConfigLoaded, areServicesHidden } = this.state;

    /**
     * The following `if` block handles the case:
     * 1. User browses to 'PREVIEW' render mode first. User has services, but
     *    the ServiceList shows no services (e.g. viewing the services of a category
     *    that has no services).
     * 2. User changes to 'EDIT' render mode.
     *
     * We fetch all services to determine if any services exist in order to display
     * <EmptyAppointmentsContainer />.
     */
    if (
      !areServicesHidden &&
      prevProps.renderMode !== renderModes.EDIT &&
      renderMode === renderModes.EDIT &&
      !isServiceExistenceSet &&
      isConfigLoaded
    ) {
      verifyServicesExistence();
    }

    if (prevProps.renderMode !== renderMode) {
      updateRenderMode(renderMode);
    }

    if (prevProps.websiteId !== websiteId) {
      updateWebsiteId(websiteId);
      this.loadConfig();
    }
  }

  componentWillUnmount() {
    ServiceListStore.removeListener('change', this.onStoreChange);
    ConfigStore.removeListener('change', this.onStoreChange);
  }

  onStoreChange() {
    this.setState(this.getStoreState());
  }

  areC1ServicesHidden() {
    const {
      areServicesLoaded,
      services,
      error,
      doServicesExist,
      areServicesHidden,
      isBusinessAddressRequired
    } = this.state;
    const {
      featureFlags: { allowEmptyBusinessAddress }
    } = ConfigStore.getState();

    if (allowEmptyBusinessAddress && !isBusinessAddressRequired) {
      return (areServicesLoaded || error) && !services.length && !doServicesExist;
    }

    return (
      areServicesHidden || ((areServicesLoaded || error) && !services.length && !doServicesExist)
    );
  }

  getContainerProps() {
    const {
      featureFlags: { debugOlaPaypalAuthorize },
      renderMode
    } = this.props;

    const isSampleDataUsed =
      this.areC1ServicesHidden() &&
      (renderMode === renderModes.DISPLAY ||
        renderMode === renderModes.ADD ||
        renderMode === renderModes.SAMPLE ||
        renderMode === renderModes.EDIT);

    const isGetStartedCtaDisplayed = this.areC1ServicesHidden() && renderMode === renderModes.EDIT;

    return {
      ...this.props,
      isSampleDataUsed,
      isGetStartedCtaDisplayed,
      areServicesHidden: this.areC1ServicesHidden(),
      featureFlags: { debugOlaPaypalAuthorize }
    };
  }

  render() {
    const {
      id: widgetId,
      category,
      section,
      renderMode,
      callToActionText,
      imageCropMethod,
      staticContent,
      websiteId,
      olaSiteBaseUrl,
      olaBenefitsZeroStateExperiment,
      isAddServicesMutatorExperiment,
      websiteCreatedDate,
      experimentReleaseDate
    } = this.props;

    const { isConfigLoaded, isFormattersSet, error, env, clsHandlerRendered, locale } = this.state;

    /**
     * Don't render anything until config is loaded, since all of the widget depends on it.
     * We don't want to try to use a value from config before it's loaded.
     *
     * `renderMode !== 'ADD'` check prevents rendering nothing in the add section mutator when
     * config returns a 404 before provisioning, since provisioning happens AFTER adding the widget.
     *
     * Also, don't render anything if an error occurs. If an error occurs we render ServiceList if
     * we didn't set any services.
     */
    if (!isConfigLoaded && renderMode !== renderModes.ADD && !error && !isFormattersSet) {
      // Need to render this to avoid layout shifts
      return <div style={{ minHeight: '100vh' }} />;
    }

    const minHeight = !error && !clsHandlerRendered ? '100vh' : 'unset';

    return (
      <ManilessProvider manilessData={ this.props }>
        <CartProvider renderMode={ renderMode } options={{ websiteId, env }}>
          <UX2.Group.Section
            category={ category }
            section={ section }
            style={ olaBenefitsZeroStateExperiment === HIVEMIND_ON ? {
              position: 'relative', minHeight, padding: '10% 0'
            } : {
              position: 'relative', minHeight
            }}
          >
            <AppointmentsRouter
              { ...this.getContainerProps() }
              mutatorProps={{
                category,
                callToActionText,
                imageCropMethod
              }}
            />
            { this.getContainerProps().isGetStartedCtaDisplayed && (
              <EmptyServiceListModal
                widgetId={ widgetId }
                ctaUrl={ `${olaSiteBaseUrl}/services/new` }
                staticContent={ staticContent }
                locale={ locale }
                olaBenefitsZeroStateExperiment={ olaBenefitsZeroStateExperiment }
                isAddServicesMutatorExperiment={ isAddServicesMutatorExperiment }
                websiteCreatedDate={ websiteCreatedDate }
                experimentReleaseDate={ experimentReleaseDate }
              />
            ) }
          </UX2.Group.Section>
        </CartProvider>
      </ManilessProvider>
    );
  }
}
