import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ConfigActionTypes from '../constants/actionTypes/Config';
import FormattersActionTypes from '../constants/actionTypes/Formatters';
import Config from '../../config';
import { dateFormats, timeFormats } from '@wsb/commerce-date-time-formatter';
import { IANAZone } from 'luxon';
import domainConfigKeys from '../constants/domainConfigKeys';

class ConfigStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      /**
       * `isConfigLoaded` is a flag that exists to prevent unnecessary hits to the API
       * to get the config. The flag is set to true after the config is first loaded.
       */
      isConfigLoaded: false,
      isFetching: false,
      isFetchingStates: false,
      areServicesHidden: false,
      error: null,
      timeZone: 'Etc/UTC',
      timeZoneName: 'Etc/UTC',
      env: null,
      olaApiHost: '',
      olaApiAccountsPath: '',
      olaApiVersionPath: '',
      websiteId: null,
      renderMode: null,
      provisionInProgress: false,
      configWasSet: false,
      countryStates: [],
      areCountryStatesLoaded: false,
      is_c2_phone_required: false,
      date_format: dateFormats.MIDDLE_ENDIAN,
      time_format: timeFormats.TWELVE_HOUR,
      currency_format: {},
      initial_service_card_count: 12,
      questions: [],
      resources: { total: 1 },
      is_membership_on: false,
      membershipAccountsOn: false,
      featureFlags: {},
      experiments: {},
      clsHandlerRendered: false,
      isBusinessAddressRequired: true
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  getApiBaseUrl({ version = 1 } = {}) {
    const { olaApiHost, olaApiVersionPath, olaApiAccountsPath } = this.getState();
    const versionPath = version === 1 ? '' : olaApiVersionPath.replace('{version}', version);
    return `${olaApiHost}${versionPath}${olaApiAccountsPath}`;
  }

  getProvisioningBaseUrl() {
    const { olaProvisioningHost } = this.getState();
    return olaProvisioningHost;
  }

  mapRootDomains(envConfig, configData) {
    const baseDomain = configData.isReseller ? envConfig.resellerRootDomain : envConfig.rootDomain;

    const domainConfigs = domainConfigKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: envConfig[key]
          .replace('{rootDomain}', baseDomain)
          .replace('{websiteId}', configData.websiteId)
      }),
      {}
    );

    return domainConfigs;
  }

  getExperimentTreatment(experiment) {
    const { experiments } = this.state;

    if (!experiments || typeof experiments !== 'object') {
      return null;
    }

    return experiments[experiment] || null;
  }

  isGopayCartOn() {
    return !!this.state.is_gopay_cart_on;
  }

  /* eslint-disable complexity */
  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;
      let envConfig;
      let domainConfig;

      switch (action.type) {
        case ConfigActionTypes.SET_CONFIG:
          envConfig = Config[data.env || 'local'];
          domainConfig = this.mapRootDomains(envConfig, data);
          return this.setState({
            ...domainConfig,
            websiteId: data.websiteId,
            isReseller: data.isReseller,
            env: data.env,
            olaApiVersionPath: envConfig.olaApiVersionPath,
            configWasSet: true,
            featureFlags: data.featureFlags,
            membershipAccountsOn: data.membershipAccountsOn
          });
        case ConfigActionTypes.REQUEST_CONFIG:
          return this.setState({
            isFetching: true,
            error: null
          });
        case ConfigActionTypes.RECEIVE_CONFIG:
          return this.setState({
            isConfigLoaded: true,
            isFetching: false,
            ...data,
            timeZoneName: data.time_zone,
            areServicesHidden: data.are_services_hidden,
            requiresMarketingConsent: data.requires_marketing_consent,
            isBusinessAddressRequired: data.is_business_address_required
          });
        case ConfigActionTypes.ERROR_RECEIVE_CONFIG:
          return this.setState({
            isFetching: false,
            error: data
          });
        case ConfigActionTypes.REQUEST_COUNTRY_STATES:
          return this.setState({
            isFetchingStates: true,
            error: null
          });
        case ConfigActionTypes.RECEIVE_COUNTRY_STATES:
          return this.setState({
            isFetchingStates: false,
            countryStates: data,
            areCountryStatesLoaded: true
          });
        case ConfigActionTypes.ERROR_RECEIVE_COUNTRY_STATES:
          return this.setState({
            isFetchingStates: false,
            error: data
          });
        case ConfigActionTypes.REQUEST_PROVISION_ACCOUNT:
          return this.setState({
            provisionInProgress: true,
            error: null
          });
        case ConfigActionTypes.SUCCESS_PROVISION_ACCOUNT:
          return this.setState({
            provisionInProgress: false
          });
        case ConfigActionTypes.ERROR_PROVISION_ACCOUNT:
          return this.setState({
            provisionInProgress: false,
            error: data
          });
        case ConfigActionTypes.SET_CLS_HANDLER_RENDERED:
          return this.setState({
            clsHandlerRendered: data
          });
        case ConfigActionTypes.UPDATE_WEBSITE_ID:
          envConfig = Config[this.state.env];
          domainConfig = this.mapRootDomains(envConfig, {
            websiteId: data,
            isReseller: this.state.isReseller
          });
          return this.setState({
            ...domainConfig,
            websiteId: data
          });
        case ConfigActionTypes.UPDATE_RENDER_MODE:
          return this.setState({
            renderMode: data
          });
        // Set IANAZone timeZone here to ensure polyfill is done
        case FormattersActionTypes.SET_FORMATTERS:
          return this.setState({
            timeZone: new IANAZone(data.timeZone)
          });
        default:
          return;
      }
    });
  }
  /* eslint-enable complexity */
}

export default new ConfigStore();
