import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import RouterActionTypes from '../constants/actionTypes/Router';

class RouterStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      currentView: null
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);

    // This prevents error from dispatching before another dispatch finishes.
    setTimeout(() => {
      this.emit('change');
    }, 0);
  }

  getState() {
    return this.state;
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;

      switch (action.type) {
        case RouterActionTypes.NAVIGATE_VIEW:
          return this.setState({
            currentView: data
          });
        default:
          return;
      }
    });
  }
}

export default new RouterStore();
