import AvailableTimeSelectionStore from './AvailableTimeSelection';
import BookingFormStore from './BookingForm';
import CategoryListStore from './CategoryList';
import ConfigStore from './Config';
import FormattersStore from './Formatters';
import RouterStore from './Router';
import ScrollWidgetNotifierStore from './ScrollWidgetNotifier';
import ServiceListStore from './ServiceList';

export default {
  AvailableTimeSelectionStore,
  BookingFormStore,
  CategoryListStore,
  ConfigStore,
  FormattersStore,
  RouterStore,
  ScrollWidgetNotifierStore,
  ServiceListStore
};
