import XHR from '../utils/xhr';
import { dispatchAction } from '../dispatcher/AppDispatcher';
import ATSActionTypes from '../constants/actionTypes/AvailableTimeSelection';
import ConfigStore from '../stores/Config';

export function selectResource(resource) {
  dispatchAction({
    type: ATSActionTypes.SELECT_RESOURCE,
    data: resource
  });
}

export function selectAvailableTime(availableTime, viewDevice) {
  dispatchAction({
    type: ATSActionTypes.SELECT_AVAILABLE_TIME,
    data: availableTime,
    viewDevice
  });
}

export function fetchAvailableTimes({ serviceId, startTime, endTime, resourceIds }) {
  return XHR.getRequest(
    `${ConfigStore.getApiBaseUrl({ version: 2 })}/services/${serviceId}/available_times`,
    {
      params: {
        start_time: startTime,
        end_time: endTime,
        resource_ids: resourceIds
      }
    }
  ).then(res => {
    // The data is passed to @wsb/ola-widget-components AvailableTimePicker.
    return res;
  });
}

export function fetchAvailableDates({ serviceId, startTime, endTime, resourceIds }) {
  return XHR.getRequest(`${ConfigStore.getApiBaseUrl()}/services/${serviceId}/available_dates`, {
    params: {
      start_time: startTime,
      end_time: endTime,
      resource_ids: resourceIds
    }
  }).then(res => {
    // The data is passed to @wsb/ola-widget-components AvailableTimePicker.
    return res;
  });
}
