import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { UX2 } from '@wsb/guac-widget-core';
import Field from '../constants/editable-field-tags';
import ScrollWidgetNotifier from '../stores/ScrollWidgetNotifier';
import dataAids from '../constants/dataAids';
import renderModes from '../constants/renderModes';
import { Fetching } from './Fetching';
import Breadcrumb from './Breadcrumb';
import views from '../constants/views';
import { trackMetric } from '../../common/utils/trackMetric';
import { VIEW_ALL_SERVICES_BREADCRUMB_CLICK } from '../constants/metrics';

import {
  AvailableTimeSelection,
  SingleEventDetails,
  BookingForm,
  BookingConfirmation,
  ServiceList1,
  ServiceList2,
  suspenseWrapper
} from './lazy';

const ServiceListMapping = { ServiceList1, ServiceList2 };

export default class AppointmentsContainer extends Component {
  static get propTypes() {
    return {
      currentView: PropTypes.string,
      title: PropTypes.string,
      callToActionText: PropTypes.string,
      imageCropMethod: PropTypes.string,
      category: PropTypes.string,
      section: PropTypes.string,
      websiteId: PropTypes.string,
      serviceListComponent: PropTypes.string,
      staticContent: PropTypes.object.isRequired,
      renderMode: PropTypes.string,
      routerLocation: PropTypes.shape({
        href: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
      }).isRequired,
      navigate: PropTypes.func,
      featureFlags: PropTypes.object,
      order: PropTypes.any,
      isInternalPage: PropTypes.bool,
      isSampleDataUsed: PropTypes.bool,
      isGetStartedCtaDisplayed: PropTypes.bool,
      areServicesHidden: PropTypes.bool,
      viewDevice: PropTypes.string,
      olaFbPixelId: PropTypes.string,
      vnextFbPixelId: PropTypes.string,
      domainName: PropTypes.string,
      businessName: PropTypes.string,
      showFacebook: PropTypes.bool,
      showTwitter: PropTypes.bool
    };
  }

  constructor(props) {
    super(props);
    this.scrollWidgetIntoView = this.scrollWidgetIntoView.bind(this);
    this.setContainerRef = this.setContainerRef.bind(this);
    this.getViewComponent = this.getViewComponent.bind(this);
  }

  setContainerRef(ref) {
    this.olaContainerRef = ref;
  }

  componentDidMount() {
    ScrollWidgetNotifier.addListener('ola', this.scrollWidgetIntoView);
  }

  componentWillUnmount() {
    ScrollWidgetNotifier.removeListener('ola', this.scrollWidgetIntoView);
  }

  scrollWidgetIntoView() {
    const { renderMode } = this.props;

    // eslint-disable-next-line react/no-find-dom-node
    const olaContainerNode = this.olaContainerRef && findDOMNode(this.olaContainerRef);

    if (!olaContainerNode) return;

    const getStickyNavHeight = () => {
      const stickyNav = document.querySelector('[id^="header_stickynav"]');

      if (stickyNav && stickyNav.offsetHeight) {
        return stickyNav.offsetHeight;
      }

      return 0;
    };

    if (renderMode === renderModes.PUBLISH && olaContainerNode.scrollIntoView) {
      return setTimeout(() => {
        const stickyNavHeight = getStickyNavHeight();
        olaContainerNode.scrollIntoView(true);
        document.body.scrollTop -= stickyNavHeight;
        if (document.documentElement) {
          document.documentElement.scrollTop -= stickyNavHeight;
        }
      }, 0);
    }

    const previewContainer = Array.from(
      document.querySelectorAll('.viewport, #render-container')
    ).find(x => x.contains(olaContainerNode));

    if (!previewContainer) return;

    setTimeout(() => {
      previewContainer.scrollTop +=
        olaContainerNode.getBoundingClientRect().top -
        previewContainer.getBoundingClientRect().top -
        getStickyNavHeight();
    }, 0);
  }

  getViewComponent() {
    const {
      navigate,
      staticContent,
      currentView,
      callToActionText,
      imageCropMethod,
      renderMode,
      routerLocation,
      category: widgetCategory,
      section,
      websiteId,
      serviceListComponent,
      isSampleDataUsed,
      isGetStartedCtaDisplayed,
      featureFlags,
      viewDevice,
      areServicesHidden,
      olaFbPixelId,
      vnextFbPixelId,
      domainName,
      businessName,
      showFacebook,
      showTwitter,
      stockImages,
      showStockImagesZeroState
    } = this.props;

    let ViewComponent, viewComponentProps;
    viewComponentProps = {
      navigate,
      routerLocation,
      staticContent,
      areServicesHidden,
      olaFbPixelId,
      vnextFbPixelId,
      section
    };

    switch (currentView) {
      case views.SERVICE_LIST:
        ViewComponent = ServiceListMapping[serviceListComponent];
        viewComponentProps = {
          ...viewComponentProps,
          renderMode,
          isSampleDataUsed,
          isGetStartedCtaDisplayed,
          imageCropMethod,
          callToActionText,
          widgetCategory,
          viewDevice,
          showFacebook,
          showTwitter,
          stockImages,
          showStockImagesZeroState
        };
        break;
      case views.AVAILABLE_TIME_SELECTION:
        ViewComponent = AvailableTimeSelection;
        viewComponentProps = {
          ...viewComponentProps,
          viewDevice,
          renderMode,
          showFacebook,
          showTwitter
        };
        break;
      case views.SINGLE_EVENT_DETAILS:
        ViewComponent = SingleEventDetails;
        viewComponentProps = { ...viewComponentProps, renderMode, showFacebook, showTwitter };
        break;
      case views.BOOKING_FORM:
        ViewComponent = BookingForm;
        viewComponentProps = {
          ...viewComponentProps,
          renderMode,
          websiteId,
          section,
          featureFlags
        };
        break;
      case views.BOOKING_CONFIRMATION:
        ViewComponent = BookingConfirmation;
        viewComponentProps = { ...viewComponentProps, domainName, businessName };
        break;
      default:
        // TODO: Error screen?
        break;
    }

    return suspenseWrapper(
      <ViewComponent
        data-aid={ dataAids.APPOINTMENTS_CURRENT_VIEW_RENDERED }
        { ...viewComponentProps }
      />
    );
  }

  onViewAllServicesClick = () => {
    trackMetric(VIEW_ALL_SERVICES_BREADCRUMB_CLICK);
    this.props.navigate(views.SERVICE_LIST);
  };

  render() {
    const { title, staticContent, currentView, order, isInternalPage, isGetStartedCtaDisplayed } =
      this.props;

    const {
      Element: { Container, Block, Heading },
      Group: { Group }
    } = UX2;

    const containerStyles = {
      position: 'relative',
      maxWidth: '100%'
    };

    return (
      <Container ref={ this.setContainerRef } style={ containerStyles }>
        <Block style={ isGetStartedCtaDisplayed ? { filter: 'blur(3px)' } : {} }>
          { currentView ? (
            <Fragment>
              { currentView === views.SERVICE_LIST ? (
                title && (
                  <Heading
                    data-aid={ dataAids.APPOINTMENTS_TITLE }
                    data-route={ Field.TITLE }
                    order={ order }
                    isInternalPage={ isInternalPage }
                  >
                    { title }
                  </Heading>
                )
              ) : (
                <Breadcrumb
                  currentView={ currentView }
                  onClick={ this.onViewAllServicesClick }
                  viewServicesText={ staticContent.viewAllServices }
                />
              ) }
              <Group style={{ position: 'relative' }}>{ this.getViewComponent() }</Group>
            </Fragment>
          ) : (
            <Fetching fetching />
          ) }
        </Block>
      </Container>
    );
  }
}
