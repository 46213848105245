import { getDateTimeFormatterI18n } from '@wsb/commerce-date-time-formatter';
import weekdayNumberI18nMap from './i18nMaps/weekdayNumbers';
import monthNumberI18nMap from './i18nMaps/monthNumbers';

export default ({ i18nize = () => {}, timeZones = [] }) =>
  getDateTimeFormatterI18n({
    i18nize,
    maps: {
      weekdays: weekdayNumberI18nMap,
      months: monthNumberI18nMap
    },
    timeZones,
    getShortWeekdayKey: weekday => `weekdays.short.${weekday}`,
    getLongWeekdayKey: weekday => `weekdays.long.${weekday}`,
    getShortMonthKey: month => `months.short.${month}`,
    getLongMonthKey: month => `months.long.${month}`,
    getTimeZoneKey: timeZone => `timeZones.${timeZone}`
  });
