import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ScrollWidgetConstants from '../constants/actionTypes/ScrollWidgetConstants';

class ScrollWidgetNotifier extends EventEmitter {
  constructor() {
    super(...arguments);

    this.register();
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;

      switch (action.type) {
        case ScrollWidgetConstants.SCROLL_WIDGET:
          this.emit(data);
          break;
        default:
          break;
      }
    });
  }
}

export default new ScrollWidgetNotifier();
