import keyMirror from 'keymirror';

export default keyMirror({
  UPDATE_CLIENT: null,
  UPDATE_LOCATION: null,
  UPDATE_MEMBERSHIP: null,
  UPDATE_MARKETING: null,
  REQUEST_SAVE_BOOKING: null,
  RECEIVE_BOOKING: null,
  ERROR_RECEIVE_BOOKING: null,
  RECEIVE_PAYPAL_ERROR: null,
  START_PAYMENT: null,
  CANCEL_PAYMENT: null,
  REQUEST_ACTIVE_PAYMENT_METHODS: null,
  RECEIVE_ACTIVE_PAYMENT_METHODS: null,
  ERROR_RECEIVE_ACTIVE_PAYMENT_METHODS: null,
  REQUEST_RAVEN_CONTACT: null,
  RECEIVE_RAVEN_CONTACT: null,
  SET_NO_RAVEN_CONTACT: null,
  CLEAR_ERRORS: null,
  UPDATE_ANSWERS: null
});
