import GoogleAnalytics from './GoogleAnalytics';
const SS_KEY = 'olaWidgetEvents';

const queueEvent = eventData => {
  if (!window.sessionStorage) return;

  let queue;
  try {
    queue = JSON.parse(window.sessionStorage.getItem(SS_KEY)) || [];
    queue.push(eventData);
  } catch (ex) {
    queue = [eventData];
  }
  window.sessionStorage.setItem(SS_KEY, JSON.stringify(queue));
};

const flushQueuedEvents = () => {
  if (!window.sessionStorage) return;

  try {
    const queue = JSON.parse(window.sessionStorage.getItem(SS_KEY)) || [];
    queue.forEach(eventData => {
      GoogleAnalytics.logEvent(eventData[2]);
      window._trfq.push(eventData);
    });
  } finally {
    window.sessionStorage.removeItem(SS_KEY);
  }
};

export const trackMetric = ({ eid, type } = {}) => {
  window._trfq = window._trfq || [];
  const eventName = 'pandc.ola.widget.' + eid;
  const eventData = ['cmdLogEvent', type, eventName];

  // We do not want to log events if C2 has not opted into tracking
  if (
    typeof window.trackingEnabledForType === 'function' &&
    !window.trackingEnabledForType('click')
  ) {
    queueEvent(eventData);
    return;
  }

  flushQueuedEvents();
  GoogleAnalytics.logEvent(eventName);
  window._trfq.push(eventData);
};

export const trackC1 = (eventName, eventPayload) => {
  const detail = {
    type: 'triggerMetrics',
    code: eventName
  };

  if (eventPayload) {
    detail.eventPayload = eventPayload;
  }

  const metricEvent = new CustomEvent('editor', { detail });
  window.dispatchEvent(metricEvent);
};
