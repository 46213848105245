const CLICK = 'click';
const IMPRESSION = 'impression';

export const LAYOUT_1_IMPRESSION = {
  eid: 'layout_1.impression',
  type: IMPRESSION
};

export const LAYOUT_2_IMPRESSION = {
  eid: 'layout_2.impression',
  type: IMPRESSION
};

export const NEW_CART_EXPERIMENT_CONTROL_IMPRESSION = {
  eid: 'restarted_experiment_new_cart.control',
  type: IMPRESSION
};

export const NEW_CART_EXPERIMENT_VARIANT_IMPRESSION = {
  eid: 'restarted_experiment_new_cart.variant',
  type: IMPRESSION
};

export const VIEW_ALL_SERVICES_BREADCRUMB_CLICK = {
  eid: 'view_all_services_breadcrumb.click',
  type: CLICK
};

export const SERVICE_LIST_BOOK_APPOINTMENT_CTA_CLICK = {
  eid: 'service_list.book_appointment_cta.click',
  type: CLICK
};

export const SERVICE_LIST_BOOK_SINGLE_EVENT_CTA_CLICK = {
  eid: 'service_list.book_single_event_cta.click',
  type: CLICK
};

export const SERVICE_LIST_BOOK_RECURRING_EVENT_CTA_CLICK = {
  eid: 'service_list.book_recurring_event_cta.click',
  type: CLICK
};

export const SERVICE_LIST_EXPAND_SERVICE_DESCRIPTION_CTA_IMPRESSION = {
  eid: 'service_list.expand_service_description_cta.impression',
  type: IMPRESSION
};

export const SERVICE_LIST_EXPAND_SERVICE_DESCRIPTION_CTA_CLICK = {
  eid: 'service_list.expand_service_description_cta.click',
  type: CLICK
};

export const SERVICE_LIST_APPOINTMENTS_TAB_IMPRESSION = {
  eid: 'service_list.appointments_tab.impression',
  type: IMPRESSION
};

export const SERVICE_LIST_APPOINTMENTS_TAB_CLICK = {
  eid: 'service_list.appointments_tab.click',
  type: CLICK
};

export const SERVICE_LIST_EVENTS_TAB_IMPRESSION = {
  eid: 'service_list.events_tab.impression',
  type: IMPRESSION
};

export const SERVICE_LIST_EVENTS_TAB_CLICK = {
  eid: 'service_list.events_tab.click',
  type: CLICK
};

export const AVAILABLE_TIMES_SELECTION_IMPRESSION = {
  eid: 'available_times_selection.impression',
  type: IMPRESSION
};

export const AVAILABLE_TIMES_SELECTION_BOOK_CTA_CLICK = {
  eid: 'available_times_selection.book_cta.click',
  type: CLICK
};

export const AVAILABLE_TIMES_SELECTION_CALENDAR_DAY_CLICK = {
  eid: 'available_times_selection.calendar_day.click',
  type: CLICK
};

export const SOCIAL_SHARING_FACEBOOK_CTA_CLICK = {
  eid: 'social_sharing.facebook_cta.click',
  type: CLICK
};

export const SOCIAL_SHARING_TWITTER_CTA_CLICK = {
  eid: 'social_sharing.twitter_cta.click',
  type: CLICK
};

export const SOCIAL_SHARING_C2_IMPRESSION = {
  eid: 'service.social_sharing.impression',
  type: IMPRESSION
};

export const AVAILABLE_TIMES_SELECTION_AVAILABLE_TIME_CLICK = {
  eid: 'available_times_selection.available_time.click',
  type: CLICK
};

export const SERVICE_PAY_LATER_LABEL_IMPRESSION = {
  eid: 'service.pay_later_label.impression',
  type: IMPRESSION
};

export const SERVICE_DEPOSIT_REQUIRED_LABEL_IMPRESSION = {
  eid: 'service.deposit_required_label.impression',
  type: IMPRESSION
};

export const SERVICE_NO_PAYMENT_LABEL_IMPRESSION = {
  eid: 'service.no_payment_label.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_APPOINTMENT_IMPRESSION = {
  eid: 'booking_form.appointment.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_SINGLE_EVENT_IMPRESSION = {
  eid: 'booking_form.single_event.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_RECURRING_EVENT_IMPRESSION = {
  eid: 'booking_form.recurring_event.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_CUSTOMER_LOCATION_FORM_IMPRESSION = {
  eid: 'booking_form.customer_location_form.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_SQUARE_PAYMENT_METHOD_IMPRESSION = {
  eid: 'booking_form.square_payment_method.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_SQUARE_PAYMENT_METHOD_SUBMIT_CTA_CLICK = {
  eid: 'booking_form.square_payment_method_submit_cta.click',
  type: CLICK
};

export const BOOKING_FORM_PAYPAL_PAYMENT_METHOD_IMPRESSION = {
  eid: 'booking_form.paypal_payment_method.impression',
  type: IMPRESSION
};

export const BOOKING_FORM_PAYPAL_PAYMENT_METHOD_CLICK = {
  eid: 'booking_form.paypal_payment_method.click',
  type: CLICK
};

export const BOOKING_FORM_BOOK_CTA_CLICK = {
  eid: 'booking_form.book_cta.click',
  type: CLICK
};

export const BOOKING_FORM_LOGIN_CTA_CLICK = {
  eid: 'booking_form.login_cta.click',
  type: CLICK
};

export const BOOKING_FORM_MEMBERSHIP_DISCLAIMER_CHECKBOX_CLICK = {
  eid: 'booking_form.membership_disclaimer_checkbox.click',
  type: CLICK
};

export const BOOKING_FORM_MARKETING_CONSENT_CHECKBOX_CLICK = {
  eid: 'booking_form.marketing_consent_checkbox.click',
  type: CLICK
};

export const SERVICE_MODIFY_CTA_CLICK = {
  eid: 'service.modify_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_IMPRESSION = {
  eid: 'booking_confirmation.impression',
  type: IMPRESSION
};

export const BOOKING_CONFIRMATION_LOCATION_URL_IMPRESSION = {
  eid: 'booking_confirmation.location_url.impression',
  type: IMPRESSION
};

export const BOOKING_CONFIRMATION_LOCATION_URL_CLICK = {
  eid: 'booking_confirmation.location_url.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_BOOK_ANOTHER_SERVICE_CTA_CLICK = {
  eid: 'booking_confirmation.book_another_service_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_ADD_TO_GOOGLE_CALENDAR_CTA_CLICK = {
  eid: 'add_to_google_calendar_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_ADD_TO_ANDROID_CALENDAR_CTA_CLICK = {
  eid: 'add_to_android_calendar_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_ADD_TO_ICAL_CALENDAR_CTA_CLICK = {
  eid: 'add_to_ical_calendar_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_ADD_TO_OUTLOOK_CALENDAR_CTA_CLICK = {
  eid: 'add_to_outlook_calendar_cta.click',
  type: CLICK
};

export const BOOKING_CONFIRMATION_ADD_TO_GOOGLE_CALENDAR_CTA_IMPRESSION = {
  eid: 'add_to_google_calendar_cta.impression',
  type: IMPRESSION
};

export const BOOKING_CONFIRMATION_ADD_TO_ANDROID_CALENDAR_CTA_IMPRESSION = {
  eid: 'add_to_android_calendar_cta.impression',
  type: IMPRESSION
};

export const BOOKING_CONFIRMATION_ADD_TO_ICAL_CALENDAR_CTA_IMPRESSION = {
  eid: 'add_to_ical_calendar_cta.impression',
  type: IMPRESSION
};

export const BOOKING_CONFIRMATION_ADD_TO_OUTLOOK_CALENDAR_CTA_IMPRESSION = {
  eid: 'add_to_outlook_calendar_cta.impression',
  type: IMPRESSION
};

export const SINGLE_EVENT_BOOK_CTA_CLICK = {
  eid: 'single_event.book_cta.click',
  type: CLICK
};

export const SERVICES_DISPLAYED_IMPRESSION = {
  eid: 'services_displayed.impression',
  type: IMPRESSION
};

// C1's metrics
export const APPOINTMENTS_SHORTCUTS_IMPRESSION = 'APPOINTMENTS_SHORTCUTS_IMPRESSION';
export const APPOINTMENTS_ZERO_STATE_IMPRESSION = 'APPOINTMENTS_ZERO_STATE_IMPRESSION';
export const APPOINTMENTS_ZERO_STATE_BENEFITS_CONTROL_IMPRESSION =
  'APPOINTMENTS_ZERO_STATE_BENEFITS_CONTROL_IMPRESSION';
export const APPOINTMENTS_ZERO_STATE_BENEFITS_VARIANT_IMPRESSION =
  'APPOINTMENTS_ZERO_STATE_BENEFITS_VARIANT_IMPRESSION';
export const APPOINTMENTS_ZERO_STATE_LEARN_MORE_CTA_CLICKED =
  'APPOINTMENTS_ZERO_STATE_LEARN_MORE_CTA_CLICKED';
export const APPOINTMENTS_ZERO_STATE_MUTATOR_IMPRESSION =
  'APPOINTMENTS_ZERO_STATE_MUTATOR_IMPRESSION';
export const APPOINTMENTS_ZERO_STATE_ADD_SERVICES_MUTATOR_CTA_CLICKED =
  'APPOINTMENTS_ZERO_STATE_ADD_SERVICES_MUTATOR_CTA_CLICKED';
export const APPOINTMENTS_SERVICE_LIST_ADD_SERVICES_MUTATOR_CTA_CLICK =
  'APPOINTMENTS_SERVICE_LIST_ADD_SERVICES_MUTATOR_CTA_CLICK';
export const APPOINTMENTS_ZERO_STATE_ADD_SERVICES_CTA_CLICKED =
  'APPOINTMENTS_ZERO_STATE_ADD_SERVICES_CTA_CLICKED';
export const C1_SERVICES_DISPLAYED_IMPRESSION = 'C1_SERVICES_DISPLAYED_IMPRESSION';
export const C1_SERVICES_NOT_DISPLAYED_IMPRESSION = 'C1_SERVICES_NOT_DISPLAYED_IMPRESSION';
export const OLA_WIDGET_ZERO_STATE_IMAGES_CONTROL_IMPRESSION =
  'OLA_WIDGET_ZERO_STATE_IMAGES_CONTROL_IMPRESSION';
export const OLA_WIDGET_ZERO_STATE_IMAGES_VARIANT_IMPRESSION =
  'OLA_WIDGET_ZERO_STATE_IMAGES_VARIANT_IMPRESSION';
// service details
export const SERVICE_DETAILS_CONTROL_IMPRESSION = 'SERVICE_DETAILS_CONTROL_IMPRESSION';
export const SERVICE_DETAILS_VARIANT_IMPRESSION = 'SERVICE_DETAILS_VARIANT_IMPRESSION';
export const SERVICE_DETAILS_MUTATOR_IMPRESSION = 'SERVICE_DETAILS_MUTATOR_IMPRESSION';
export const SERVICE_DETAILS_BUSINESS_ADDRESS_MISSING_IMPRESSION =
  'SERVICE_DETAILS_BUSINESS_ADDRESS_MISSING_IMPRESSION';
export const SERVICE_DETAILS_BUSINESS_SETTINGS_CTA_CLICK =
  'SERVICE_DETAILS_BUSINESS_SETTINGS_CTA_CLICK';
export const SERVICE_DETAILS_SERVICE_SETTINGS_CTA_CLICK =
  'SERVICE_DETAILS_SERVICE_SETTINGS_CTA_CLICK';
export const SERVICE_DETAILS_SAVE_CTA_CLICK = 'SERVICE_DETAILS_SAVE_CTA_CLICK';
export const SERVICE_DETAILS_TIME_TO_SAVE_CLICK = 'SERVICE_DETAILS_TIME_TO_SAVE_CLICK';
export const SERVICE_DETAILS_DELETE_MODAL_CONFIRM_CTA_CLICK =
  'SERVICE_DETAILS_DELETE_MODAL_CONFIRM_CTA_CLICK';
export const SERVICE_DETAILS_ERROR_ALERT_IMPRESSION = 'SERVICE_DETAILS_ERROR_ALERT_IMPRESSION';
