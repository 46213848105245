import { Dispatcher } from 'flux';

const AppDispatcher = new Dispatcher();

export function dispatchAction(action) {
  AppDispatcher.dispatch({
    action: action
  });
}

export default AppDispatcher;
