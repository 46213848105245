import XHR from '../utils/xhr';
import { dispatchAction } from '../dispatcher/AppDispatcher';
import ConfigActionTypes from '../constants/actionTypes/Config';
import renderModes from '../constants/renderModes';
import ConfigStore from '../stores/Config';

function requestConfig() {
  return {
    type: ConfigActionTypes.REQUEST_CONFIG
  };
}

function receiveConfig(config = {}) {
  return {
    type: ConfigActionTypes.RECEIVE_CONFIG,
    data: config
  };
}

function errorReceiveConfig(error) {
  return {
    type: ConfigActionTypes.ERROR_RECEIVE_CONFIG,
    data: error
  };
}

function requestProvisionAccount() {
  return {
    type: ConfigActionTypes.REQUEST_PROVISION_ACCOUNT
  };
}

function successProvisionAccount() {
  return {
    type: ConfigActionTypes.SUCCESS_PROVISION_ACCOUNT
  };
}

function errorProvisionAccount(error) {
  return {
    type: ConfigActionTypes.ERROR_PROVISION_ACCOUNT,
    data: error
  };
}

// Need to update renderMode so that provisionOlaAccount will know when to provision
export function updateRenderMode(renderMode) {
  dispatchAction({
    type: ConfigActionTypes.UPDATE_RENDER_MODE,
    data: renderMode
  });
}

// Need to update websiteId in the case that websiteId starts off as null
export function updateWebsiteId(websiteId) {
  dispatchAction({
    type: ConfigActionTypes.UPDATE_WEBSITE_ID,
    data: websiteId
  });
}

export function setClsHandlerRendered(data) {
  dispatchAction({
    type: ConfigActionTypes.SET_CLS_HANDLER_RENDERED,
    data
  });
}

function provisionOlaAccount() {
  const { websiteId, provisionInProgress, renderMode } = ConfigStore.getState();

  // Don't try to provision twice or provision in the ADD section.
  if (provisionInProgress || renderMode === renderModes.ADD) return;

  dispatchAction(requestProvisionAccount());

  return XHR.postRequest(`${ConfigStore.getProvisioningBaseUrl()}`, {
    data: { account_uid: websiteId }
  })
    .then(() => {
      dispatchAction(successProvisionAccount());
      return fetchConfig();
    })
    .catch(() => {
      // TODO: Get actual error to pass as argument
      dispatchAction(errorProvisionAccount('error'));
    });
}

export function setConfig(config) {
  const { configWasSet } = ConfigStore.getState();

  if (configWasSet) return; // Don't set config more than once

  dispatchAction({
    type: ConfigActionTypes.SET_CONFIG,
    data: config
  });
}

export function fetchConfig() {
  const configState = ConfigStore.getState();
  const { isConfigLoaded, isFetching, websiteId } = configState;

  // If the config was already loaded, don't load it again to prevent more calls to the API.
  if (isConfigLoaded) {
    return Promise.resolve({ isSuccess: true });
  }

  if (isFetching || !websiteId) {
    return Promise.resolve({ isSuccess: false });
  }

  dispatchAction(requestConfig());

  return XHR.getRequest(`${ConfigStore.getApiBaseUrl()}/config`)
    .then(res => {
      const { config } = res;
      dispatchAction(receiveConfig(config));
      return { isSuccess: true };
    })
    .catch(err => {
      // TODO: Get actual error to pass as argument
      dispatchAction(errorReceiveConfig('error'));

      if (err.error && err.error.status_code === 404) {
        return provisionOlaAccount();
      }
    });
}

export function reportWidgetAdded() {
  return XHR.postRequest(`${ConfigStore.getApiBaseUrl()}/widgets`);
}

function requestCountryStates() {
  return {
    type: ConfigActionTypes.REQUEST_COUNTRY_STATES
  };
}

function receiveCountryStates(data) {
  return {
    type: ConfigActionTypes.RECEIVE_COUNTRY_STATES,
    data
  };
}

function errorReceiveCountryStates(error) {
  return {
    type: ConfigActionTypes.ERROR_RECEIVE_COUNTRY_STATES,
    data: error
  };
}

export function fetchCountryStates() {
  const {
    areCountryStatesLoaded,
    isFetchingStates,
    business_country: { id: countryId }
  } = ConfigStore.getState();

  if (areCountryStatesLoaded || isFetchingStates) {
    return;
  }

  dispatchAction(requestCountryStates());
  return XHR.getRequest(`${ConfigStore.getApiBaseUrl()}/countries/${countryId}/states`)
    .then(res => {
      dispatchAction(receiveCountryStates(res.sort((a, b) => a.name.localeCompare(b.name))));
    })
    .catch(err => {
      dispatchAction(errorReceiveCountryStates(err));
    });
}
