import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ManilessContext = createContext({});

const ManilessProvider = ({ manilessData, children }) => {
  return <ManilessContext.Provider value={ manilessData }>{ children }</ManilessContext.Provider>;
};

ManilessProvider.propTypes = {
  manilessData: PropTypes.object,
  children: PropTypes.any
};

const useManiless = () => useContext(ManilessContext);

export const { Consumer: ManilessConsumer } = ManilessContext;

export { ManilessProvider, useManiless };
