import keyMirror from 'keymirror';

export default keyMirror({
  SELECT_SERVICE: null,
  REQUEST_SERVICES: null,
  RECEIVE_SERVICES: null,
  SET_SERVICE_EXISTENCE: null,
  ERROR_RECEIVE_SERVICES: null,
  SHOW_ALL_SERVICES: null,
  UPDATE_SERVICE_TYPE: null,
  CATEGORIZE_SERVICES: null,
  STOP_FETCHING: null
});
