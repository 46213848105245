// - Maybe look into using Flux Utils (flux/utils) vs EventEmitter & manually listening to our store's changes.
import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import CategoryListActionTypes from '../constants/actionTypes/CategoryList';
import ConfigActionTypes from '../constants/actionTypes/Config';

class CategoryListStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      isFetching: false,
      error: null,
      categories: [],
      selectedCategoryId: null
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;

      switch (action.type) {
        case CategoryListActionTypes.REQUEST_CATEGORIES:
          return this.setState({
            isFetching: true,
            error: null
          });
        case CategoryListActionTypes.RECEIVE_CATEGORIES:
          return this.setState({
            isFetching: false,
            error: null,
            categories: data
          });
        case CategoryListActionTypes.ERROR_RECEIVE_CATEGORIES:
          return this.setState({
            isFetching: false,
            error: data
          });
        case CategoryListActionTypes.SELECT_CATEGORY:
          return this.setState({
            selectedCategoryId: data
          });
        case ConfigActionTypes.SUCCESS_PROVISION_ACCOUNT:
          return this.setState({
            error: null
          });
        default:
          return;
      }
    });
  }
}

export default new CategoryListStore();
