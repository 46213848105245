import React from 'react';
import PropTypes from 'prop-types';
import { CartProvider as GopayCartProvider } from '@pnc/gopay-cart-setup-util/lib/react';
import ConfigStore from '../stores/Config';
import renderModes from '../constants/renderModes';

const CartProvider = ({ children, renderMode, ...props }) => {
  if (!ConfigStore.isGopayCartOn() || renderMode !== renderModes.PUBLISH) {
    return children;
  }
  return <GopayCartProvider { ...props }>{ children }</GopayCartProvider>;
};

CartProvider.propTypes = {
  children: PropTypes.any,
  renderMode: PropTypes.string
};

export default CartProvider;
