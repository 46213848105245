import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import dataAids from '../constants/dataAids';
import views from '../constants/views';

export class Breadcrumb extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { currentView, onClick, viewServicesText } = this.props;
    const { Block, MoreLink } = UX2.Element;
    const styles = {
      wrapper: {
        '@xs': {
          marginBottom: 'medium'
        },
        '@sm': {
          marginBottom: currentView !== views.SERVICE_LIST ? 'xlarge' : 'xsmall'
        }
      },
      link: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'left',
        '@xs-only': {
          justifyContent: 'center'
        }
      }
    };

    return (
      <Block data-aid={ dataAids.APPOINTMENTS_SERVICE_LIST_BUTTON } style={ styles.wrapper }>
        <MoreLink.Backward onClick={ onClick } style={ styles.link } tag='span'>
          { viewServicesText }
        </MoreLink.Backward>
      </Block>
    );
  }
}

Breadcrumb.propTypes = {
  currentView: PropTypes.string,
  onClick: PropTypes.func,
  viewServicesText: PropTypes.string
};

export default Breadcrumb;
