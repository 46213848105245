// - Maybe look into using Flux Utils (flux/utils) vs EventEmitter & manually listening to our store's changes.
import { assign } from 'lodash';
import EventEmitter from '@wsb/commerce-event-emitter';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ServiceListActionTypes from '../constants/actionTypes/ServiceList';
import ConfigActionTypes from '../constants/actionTypes/Config';

class ServiceListStore extends EventEmitter {
  constructor() {
    super(...arguments);

    this.state = {
      isFetching: false,
      error: null,
      selectedService: {},
      services: [],
      categorizedServices: [],
      doServicesExist: false,
      areAllServicesShown: false,
      isServiceExistenceSet: false,
      areServicesLoaded: false,
      areServicesCategorized: false,
      serviceType: 'appointments'
    };

    this.register();
  }

  setState(newState) {
    assign(this.state, newState);
    this.emit('change');
  }

  getState() {
    return this.state;
  }

  register() {
    this.dispatchToken = AppDispatcher.register(payload => {
      const { action } = payload;
      const { data } = action;
      switch (action.type) {
        case ServiceListActionTypes.SHOW_ALL_SERVICES:
          return this.setState({
            areAllServicesShown: true
          });
        case ServiceListActionTypes.SELECT_SERVICE:
          return this.setState({
            selectedService: data,
            isServiceExistenceSet: true,
            doServicesExist: true
          });
        case ServiceListActionTypes.REQUEST_SERVICES:
          return this.setState({
            isFetching: true,
            error: null
          });
        case ServiceListActionTypes.RECEIVE_SERVICES:
          return this.setState({
            isFetching: false,
            error: null,
            services: data,
            areAllServicesShown: false,
            areServicesLoaded: true
          });
        case ServiceListActionTypes.SET_SERVICE_EXISTENCE:
          return this.setState({
            isServiceExistenceSet: true,
            doServicesExist: data
          });
        case ServiceListActionTypes.ERROR_RECEIVE_SERVICES:
          return this.setState({
            isFetching: false,
            error: data
          });
        case ServiceListActionTypes.UPDATE_SERVICE_TYPE:
          return this.setState({
            serviceType: data
          });
        case ServiceListActionTypes.CATEGORIZE_SERVICES:
          return this.setState({
            categorizedServices: data,
            areServicesCategorized: true
          });
        case ConfigActionTypes.SUCCESS_PROVISION_ACCOUNT:
          /**
           * After provisioning, there are no services so we just remove error
           * message from the original call to a non-existent account.
           */
          return this.setState({
            error: null
          });
        case ServiceListActionTypes.STOP_FETCHING:
          return this.setState({
            isFetching: false
          });
        default:
          return;
      }
    });
  }
}

export default new ServiceListStore();
