import XHR from '../utils/xhr';
import { dispatchAction } from '../dispatcher/AppDispatcher';
import ServiceListActionTypes from '../constants/actionTypes/ServiceList';
import { selectAvailableTime, selectResource } from './AvailableTimeSelection';
import ConfigStore from '../stores/Config';
import ServiceListStore from '../stores/ServiceList';
import { isSingleEvent } from '../utils/recurrenceType';
import CategoryList from '../stores/CategoryList';

export function fetchServiceBySlug(slug) {
  const { services } = ServiceListStore.getState();

  const service = services.find(s => s.slug === slug);

  if (service) {
    selectService(service);
    return Promise.resolve(true);
  }

  let version = 2;
  if (ConfigStore.isGopayCartOn()) version = 3;

  return XHR.getRequest(`${ConfigStore.getApiBaseUrl({ version })}/services/slug/${slug}`)
    .then(res => {
      selectService(res.service);
      return true;
    })
    .catch(() => {
      return false;
    });
}

export function selectService(service) {
  dispatchAction({
    type: ServiceListActionTypes.SELECT_SERVICE,
    data: service
  });

  if (isSingleEvent(service.recurrence_type)) {
    selectOneOffService(service);
  }
}

function selectOneOffService(service) {
  selectAvailableTime(service.start_time);
  selectResource(service.resources[0]);
}

export function showAllServices() {
  dispatchAction({
    type: ServiceListActionTypes.SHOW_ALL_SERVICES
  });
}

function requestServices() {
  return {
    type: ServiceListActionTypes.REQUEST_SERVICES
  };
}

function receiveServices(services = []) {
  return {
    type: ServiceListActionTypes.RECEIVE_SERVICES,
    data: services
  };
}

function setServiceExistence(value) {
  return {
    type: ServiceListActionTypes.SET_SERVICE_EXISTENCE,
    data: value
  };
}

function errorReceiveServices(error) {
  return {
    type: ServiceListActionTypes.ERROR_RECEIVE_SERVICES,
    data: error
  };
}

function stopFetching() {
  return {
    type: ServiceListActionTypes.STOP_FETCHING
  };
}

function updateCategorizedServices(categorizedServices) {
  return {
    type: ServiceListActionTypes.CATEGORIZE_SERVICES,
    data: categorizedServices
  };
}

export function updateServiceType(serviceType) {
  dispatchAction({
    type: ServiceListActionTypes.UPDATE_SERVICE_TYPE,
    data: serviceType
  });
}

export function fetchServices(categoryId = null) {
  const { widget_max_services_per_page: perPage } = ConfigStore.getState();

  dispatchAction(requestServices());

  let params = {
    per_page: perPage
  };

  let additionalParam = { with_category_ids: true };

  let version = 2;

  if (ConfigStore.isGopayCartOn()) {
    version = 3;

    if (categoryId) {
      additionalParam = { category_id: categoryId };
    }
  }

  params = {
    ...params,
    ...additionalParam
  };

  return XHR.getRequest(`${ConfigStore.getApiBaseUrl({ version })}/services`, {
    params
  })
    .then(res => {
      const { results, total_records: totalRecords } = res;

      dispatchAction(receiveServices(results));
      dispatchAction(setServiceExistence(totalRecords > 0));

      return true;
    })
    .catch(() => {
      // TODO: Get actual error to pass into errorReceiveServices.
      dispatchAction(errorReceiveServices('error'));
    });
}

const flatten = collection => [].concat.apply([], collection);
const mapServiceIds = service => service.id;
const mapCategoryServices = category => category.services;
const filterUncategorizedServices = categorizedServiceIds => service =>
  !categorizedServiceIds.includes(service.id);

export function categorizeServices(services, categories) {
  const regularCategories = categories.filter(category => !category.permanent);

  Promise.all(
    regularCategories.map(category => {
      let url = `${ConfigStore.getApiBaseUrl({ version: 2 })}/categories/${category.id}/services`;
      let params = { only_available_services: true };

      if (ConfigStore.isGopayCartOn()) {
        url = `${ConfigStore.getApiBaseUrl({ version: 3 })}/services`;
        params = { category_id: category.id };
      }

      return XHR.getRequest(url, { params }).then(({ results }) => ({
        id: category.id,
        name: category.name,
        services: results
      }));
    })
  )
    .then(categorizedServices => {
      const serviceIds = flatten(categorizedServices.map(mapCategoryServices)).map(mapServiceIds);

      return [
        ...categorizedServices,
        {
          id: null,
          name: null,
          services: services.filter(filterUncategorizedServices(serviceIds))
        }
      ].filter(category => Boolean(category.services.length));
    })
    .then(allServices => {
      dispatchAction(updateCategorizedServices(allServices));
      dispatchAction(stopFetching());
    })
    .catch(() => {
      // TODO: Get actual error to pass into errorReceiveCategoryServices.
      dispatchAction(errorReceiveServices('error'));
    });
}

export function fetchCategoryServices(categoryId) {
  const { widget_max_services_per_page: perPage } = ConfigStore.getState();
  dispatchAction(requestServices());

  return XHR.getRequest(
    `${ConfigStore.getApiBaseUrl({ version: 2 })}/categories/${categoryId}/services`,
    {
      params: {
        only_available_services: true,
        per_page: perPage
      }
    }
  )
    .then(res => {
      const { results, total_records: totalRecords } = res;

      dispatchAction(receiveServices(results));

      // Set so that the `doServicesExist` flag will be set and we don't need to make extra API call
      if (totalRecords > 0) {
        dispatchAction(setServiceExistence(totalRecords > 0));
      }
    })
    .catch(() => {
      // TODO: Get actual error to pass into errorReceiveCategoryServices.
      dispatchAction(errorReceiveServices('error'));
    });
}

export function verifyServicesExistence() {
  const { widget_max_services_per_page: perPage } = ConfigStore.getState();

  dispatchAction(requestServices());

  return XHR.getRequest(`${ConfigStore.getApiBaseUrl({ version: 2 })}/services`, {
    params: { per_page: perPage }
  })
    .then(res => {
      const { total_records: totalRecords } = res;

      dispatchAction(setServiceExistence(totalRecords > 0));

      if (totalRecords === 0) {
        dispatchAction(stopFetching());
      }

      return true;
    })
    .catch(() => dispatchAction(errorReceiveServices('error')));
}

export const saveServicePosition = ({ serviceId, targetServiceId, position }) => {
  const olaBaseApiUrlV1 = ConfigStore.getApiBaseUrl();
  const { categories = [] } = CategoryList.getState();
  const permanentCategory = categories.find(category => category.permanent) || {};

  return XHR.patchRequest(
    `${olaBaseApiUrlV1}/categories/${permanentCategory.id}/services/position`,
    {
      params: {
        service_id: serviceId,
        target_service_id: targetServiceId,
        position
      }
    }
  );
};
