function setFixedHeaders(req) {
  req.withCredentials = true;
  req.setRequestHeader('Accept', '*/*');
  req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
}

function setCustomHeaders(req, headers) {
  const keys = Object.keys(headers);
  keys.forEach(key => req.setRequestHeader(key, headers[key]));
}

function buildParamString(key, value) {
  return Array.isArray(value)
    ? value.map(v => `${key}[]=${encodeURIComponent(v)}`).join('&')
    : `${key}=${encodeURIComponent(value)}`;
}

function appendParams(url, params) {
  if (!params) return url;

  const paramSeparator = url.indexOf('?') === -1 ? '?' : '&';
  const keys = Object.keys(params);
  const paramString = keys
    .map(key => (params[key] ? buildParamString(key, params[key]) : ''))
    .join('&');
  const paramAppend = paramString ? `${paramSeparator}${paramString}` : '';

  return `${url}${paramAppend}`;
}

class XHR {
  getRequest(url, args = {}) {
    return this._buildMethod('GET', { url, ...args });
  }

  postRequest(url, args = {}) {
    return this._buildMethod('POST', { url, ...args });
  }

  putRequest(url, args = {}) {
    return this._buildMethod('PUT', { url, ...args });
  }

  patchRequest(url, args = {}) {
    return this._buildMethod('PATCH', { url, ...args });
  }

  deleteRequest(url, args = {}) {
    return this._buildMethod('DELETE', { url, ...args });
  }

  _buildMethod(method, { url, data, headers, params }) {
    const requestParams = {
      method,
      url,
      data,
      headers,
      params
    };
    return this._makeRequest(requestParams);
  }

  _makeRequest(opts) {
    const { method, url, headers, params } = opts;
    let { data } = opts;
    let retryCountdown = 0;

    return new Promise(function (resolve, reject) {
      function createReq() {
        const xhr = new XMLHttpRequest();
        xhr.open(method, appendParams(url, params));
        let json = {};

        xhr.onload = function () {
          try {
            json = JSON.parse(xhr.response);
          } catch (e) {
            // Unable to parse JSON
            json = {};
          }

          if (this.status >= 400) {
            const error = new Error(
              json.error || json.message || xhr.responseText || 'unexpected error'
            );
            error.status_code = this.status;
            /* eslint-disable prefer-promise-reject-errors */
            reject({
              error: error,
              response: json
            });
            /* eslint-enable prefer-promise-reject-errors */
            return;
          }
          resolve(json);
        };

        function handleNetworkError() {
          if (retryCountdown-- > 0) {
            createReq();
          } else {
            /* eslint-disable prefer-promise-reject-errors */
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
            /* eslint-enable prefer-promise-reject-errors */
          }
        }

        xhr.onerror = handleNetworkError;
        xhr.ontimeout = handleNetworkError;

        setFixedHeaders(xhr);

        if (headers) {
          setCustomHeaders(xhr, headers);
        }

        if (data && typeof data === 'object') {
          data = JSON.stringify(data);
        } else if (typeof data === 'undefined') {
          data = null;
        }
        xhr.send(data);
      }
      createReq();
    });
  }
}

export default new XHR();
