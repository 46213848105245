import XHR from '../utils/xhr';
import { dispatchAction } from '../dispatcher/AppDispatcher';
import RouterActionTypes from '../constants/actionTypes/Router';
import ConfigStore from '../stores/Config';
import ServiceListStore from '../stores/ServiceList';
import AvailableTimeSelectionStore from '../stores/AvailableTimeSelection';

export const navigateView = view => {
  dispatchAction({
    type: RouterActionTypes.NAVIGATE_VIEW,
    data: view
  });
};

export function requestDeepLinkStatus() {
  return {
    type: RouterActionTypes.REQUEST_DEEP_LINK_STATUS
  };
}

export function receiveDeepLinkStatus() {
  return {
    type: RouterActionTypes.RECEIVE_DEEP_LINK_STATUS
  };
}

export function receiveDeepLinkError(error) {
  dispatchAction({
    type: RouterActionTypes.RECEIVE_DEEP_LINK_ERROR,
    data: error
  });
}

export function verifyServiceDeepLink() {
  dispatchAction(requestDeepLinkStatus());
  const {
    selectedAvailableTime,
    selectedResource: { id: selectedResourceId }
  } = AvailableTimeSelectionStore.getState();
  const {
    selectedService: { slug }
  } = ServiceListStore.getState();
  const url = `${ConfigStore.getApiBaseUrl()}/services/slug/${slug}/availability`;
  return XHR.getRequest(url, {
    params: {
      start_time: selectedAvailableTime,
      resource_ids: [selectedResourceId]
    }
  })
    .then(() => {
      dispatchAction(receiveDeepLinkStatus());
    })
    .catch(error => {
      receiveDeepLinkError((error.response && error.response.data) || error);
    });
}
