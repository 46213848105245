import keyMirror from 'keymirror';

export default keyMirror({
  PREVIEW: null,
  EDIT: null,
  PUBLISH: null,
  DISPLAY: null,
  ADD: null,
  SAMPLE: null
});
