import React, { lazy, Suspense } from 'react';

const suspenseWrapper = children => {
  return typeof window !== 'undefined' ? <Suspense fallback={ null }>{ children }</Suspense> : null;
};
const AvailableTimeSelection = lazy(() => import('../AvailableTimeSelection'));
const SingleEventDetails = lazy(() => import('../SingleEventDetails'));
const BookingForm = lazy(() => import('../BookingForm'));
const BookingConfirmation = lazy(() => import('../BookingConfirmation'));
const ServiceList1 = lazy(() => import('../../../layouts/appointments1/ServiceList'));
const ServiceList2 = lazy(() => import('../../../layouts/appointments2/ServiceList'));

export {
  AvailableTimeSelection,
  SingleEventDetails,
  BookingForm,
  BookingConfirmation,
  ServiceList1,
  ServiceList2,
  suspenseWrapper
};
