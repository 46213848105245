const numberFormatterMapper = currencyFormat => ({
  numberFormat: {
    thousandsSeparator: currencyFormat.thousands_separator,
    decimalMark: currencyFormat.decimal_mark
  },
  currencyFormat: {
    isoCode: currencyFormat.iso_code,
    symbol: currencyFormat.symbol,
    symbolFirst: currencyFormat.symbol_first,
    decimalPlaces: currencyFormat.decimal_places
  }
});

export default numberFormatterMapper;
