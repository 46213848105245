import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import RouterHelper from '../utils/RouterHelper';
import { SERVICE_PARAM, AVAILABLE_TIME_PARAM, RESOURCE_PARAM } from '../constants/router';
import stores from '../stores';
import views from '../constants/views';
import renderModes from '../constants/renderModes';
import AppointmentsContainer from './AppointmentsContainer';
import { fetchServiceBySlug, selectService } from '../actions/ServiceList';
import { selectAvailableTime, selectResource } from '../actions/AvailableTimeSelection';
import { navigateView, receiveDeepLinkError, verifyServiceDeepLink } from '../actions/Router';
import hasNoWindow from '../utils/hasNoWindow';
import { isSingleEvent } from '../utils/recurrenceType';

const { RouterStore, ServiceListStore, AvailableTimeSelectionStore, ConfigStore } = stores;

class Router extends Component {
  static get propTypes() {
    return {
      renderMode: PropTypes.string,
      isSampleDataUsed: PropTypes.bool,
      isGetStartedCtaDisplayed: PropTypes.bool,
      currentPageRoute: PropTypes.string,
      mutatorProps: PropTypes.object
    };
  }

  constructor() {
    super(...arguments);
    this.state = this.getStoreState();

    this.navigate = this.navigate.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.updateViewFromLocation = this.updateViewFromLocation.bind(this);
  }

  getStoreState() {
    const { currentView } = RouterStore.getState();

    return { currentView };
  }

  onStoreChange() {
    this.setState(this.getStoreState());
  }

  componentDidMount() {
    RouterStore.addListener('change', this.onStoreChange);
    window.addEventListener('popstate', this.updateViewFromLocation);

    this.updateViewFromLocation();
  }

  componentWillUnmount() {
    RouterStore.removeListener('change', this.onStoreChange);
    window.removeEventListener('popstate', this.updateViewFromLocation);
  }

  componentDidUpdate(prevProps) {
    // Switching renderMode clears out all query params
    if (this.props.renderMode !== prevProps.renderMode) {
      this.navigate(views.SERVICE_LIST);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { renderMode } = this.props;
    if (
      renderMode === renderModes.EDIT ||
      this.state.currentView !== nextState.currentView ||
      renderMode !== nextProps.renderMode ||
      this.props.isSampleDataUsed !== nextProps.isSampleDataUsed ||
      !isEqual(this.props.mutatorProps, nextProps.mutatorProps) ||
      !isEqual(this.props.isGetStartedCtaDisplayed, nextProps.isGetStartedCtaDisplayed)
    ) {
      return true;
    }

    return false;
  }

  handleResourceAndAvailableTimeParams(service) {
    const resourceId = parseInt(RouterHelper.getSearchParam(RESOURCE_PARAM), 10);
    const availableTime = RouterHelper.getSearchParam(AVAILABLE_TIME_PARAM);

    if (isSingleEvent(service.recurrence_type)) {
      if (ConfigStore.isGopayCartOn()) {
        return this.navigate(views.SINGLE_EVENT_DETAILS);
      }

      return this.navigate(views.BOOKING_FORM);
    }

    if (!resourceId || !availableTime) {
      return this.navigate(views.AVAILABLE_TIME_SELECTION);
    }

    const resource = service.resources.find(r => r.id === resourceId);
    selectAvailableTime(availableTime);
    if (resource) {
      selectResource(resource);
      verifyServiceDeepLink();
    } else {
      receiveDeepLinkError('error');
    }
    this.navigate(views.BOOKING_FORM);
  }

  updateViewFromLocation() {
    if (hasNoWindow()) return;

    const { renderMode } = this.props;

    const slug =
      renderMode === renderModes.PUBLISH
        ? RouterHelper.getFriendlySlug() || RouterHelper.getSearchParam(SERVICE_PARAM) // Support old query linked URLs
        : RouterHelper.getSearchParam(SERVICE_PARAM);

    if (!slug) {
      return this.navigate(views.SERVICE_LIST);
    }

    if (
      renderMode === renderModes.PUBLISH &&
      window._OLA_DATA &&
      window._OLA_DATA.service.slug === slug
    ) {
      setTimeout(() => {
        selectService(window._OLA_DATA.service);
        this.handleResourceAndAvailableTimeParams(window._OLA_DATA.service);
      }, 0);
    } else {
      fetchServiceBySlug(slug).then(isSuccess => {
        if (!isSuccess) {
          return this.navigate(views.SERVICE_LIST);
        }
        const service = ServiceListStore.getState().selectedService;
        this.handleResourceAndAvailableTimeParams(service);
      });
    }
  }

  pushToHistory(newUrl) {
    if (!newUrl || hasNoWindow() || newUrl === window.location.href) return;

    window.history.pushState({}, null, newUrl);
  }

  getUrl(view = this.state.currentView) {
    let newUrl;
    const { renderMode, currentPageRoute } = this.props;

    const { slug } = ServiceListStore.getState().selectedService;
    const { selectedAvailableTime, selectedResource } = AvailableTimeSelectionStore.getState();

    switch (view) {
      case views.SERVICE_LIST:
      case views.BOOKING_CONFIRMATION:
        newUrl = RouterHelper.getPagePath();
        break;
      case views.SINGLE_EVENT_DETAILS:
      case views.AVAILABLE_TIME_SELECTION:
        newUrl =
          renderMode === renderModes.PUBLISH
            ? RouterHelper.buildServicePath(slug, currentPageRoute)
            : RouterHelper.updateSearchParam(SERVICE_PARAM, slug);

        newUrl = RouterHelper.removeSearchParam(AVAILABLE_TIME_PARAM, newUrl);
        newUrl = RouterHelper.removeSearchParam(RESOURCE_PARAM, newUrl);
        break;
      case views.BOOKING_FORM:
        newUrl =
          renderMode === renderModes.PUBLISH
            ? RouterHelper.buildServicePath(slug, currentPageRoute)
            : RouterHelper.updateSearchParam(SERVICE_PARAM, slug);

        newUrl = RouterHelper.updateSearchParam(
          AVAILABLE_TIME_PARAM,
          selectedAvailableTime,
          newUrl
        );
        if (selectedResource) {
          newUrl = RouterHelper.updateSearchParam(RESOURCE_PARAM, selectedResource.id, newUrl);
        }
        break;
      default:
        view = views.SERVICE_LIST;
        newUrl = RouterHelper.getPagePath();
        break;
    }

    return newUrl;
  }

  navigate(view) {
    if (hasNoWindow()) return;

    this.pushToHistory(this.getUrl(view));
    setTimeout(() => navigateView(view), 0);
  }

  render() {
    const { currentView } = this.state;

    return (
      <AppointmentsContainer
        { ...this.props }
        currentView={ currentView }
        navigate={ this.navigate }
        routerLocation={ new URL(this.getUrl()) }
      />
    );
  }
}

export default Router;
