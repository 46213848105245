/* eslint-disable id-length */
import keyMirror from 'keymirror';

export default keyMirror({
  FETCHING_LOADER_RENDERED: null,

  SERVICE_LIST_NO_SERVICES: null,
  SERVICE_LIST_SEE_ALL_LINK: null,
  SERVICE_LIST_ERROR: null,
  SERVICE_LIST_RECURRENCE_TYPE_TABS: null,
  SERVICE_LIST_TIME_ZONE: null,

  SERVICE_LIST_ITEM_SERVICE_EVENT_DATE: null,
  SERVICE_LIST_ITEM_SERVICE_NAME: null,
  SERVICE_LIST_ITEM_SERVICE_DESCRIPTION: null,
  SERVICE_LIST_ITEM_SERVICE_DESCRIPTION_MOBILE: null,
  SERVICE_LIST_ITEM_BOOK_BUTTON: null,
  SERVICE_LIST_ITEM_ASSET: null,

  CATEGORY_SEE_ALL_LINK: null,
  CATEGORY_NAME: null,
  CATEGORY_SEPARATOR: null,

  DURATION_AND_COST_SEPARATOR: null,

  APPOINTMENTS_TITLE: null,
  APPOINTMENTS_SERVICE_LIST_BUTTON: null,
  APPOINTMENTS_CURRENT_VIEW_RENDERED: null,

  BOOKING_FORM_LOGIN_LINK: null,
  BOOKING_FORM_LOGOUT_LINK: null,
  BOOKING_FORM_FIRST_NAME_INPUT: null,
  BOOKING_FORM_LAST_NAME_INPUT: null,
  BOOKING_FORM_PHONE_NUMBER_INPUT: null,
  BOOKING_FORM_EMAIL_INPUT: null,
  BOOKING_FORM_BOOK_APPOINTMENT_BUTTON: null,
  BOOKING_FORM_INPUT_ERROR: null,
  BOOKING_FORM_DEEP_LINK_ERROR: null,
  BOOKING_FORM_ADDRESS1_INPUT: null,
  BOOKING_FORM_ADDRESS2_INPUT: null,
  BOOKING_FORM_POSTAL_CODE_INPUT: null,
  BOOKING_FORM_CITY_INPUT: null,
  BOOKING_FORM_PAYPAL_BUTTON: null,
  BOOKING_FORM_SQUARE_FORM: null,
  BOOKING_FORM_SQUARE_GIFT_CARD_FORM: null,
  BOOKING_FORM_BOOK_ANOTHER_SERVICE_BUTTON: null,
  BOOKING_FORM_DEPOSIT_AMOUNT: null,
  BOOKING_FORM_RESOURCE: null,
  BOOKING_FORM_DISCLAIMER_CHECKBOX: null,
  BOOKING_FORM_MARKETING_CHECKBOX: null,
  BOOKING_FORM_DISCLAIMER_TEXT: null,
  BOOKING_FORM_MARKETING_TEXT: null,
  BOOKING_QUESTIONS: null,
  BOOKING_FORM_QUESTION_VALIDATION_ERROR: null,

  BOOKING_CONFIRMATION_SERVICE_NAME: null,
  BOOKING_CONFIRMATION_PAID_AMOUNT: null,
  BOOKING_CONFIRMATION_SERVICE_BALANCE: null,
  BOOKING_CONFIRMATION_MESSAGE: null,
  BOOKING_CONFIRMATION_SELECTED_RESOURCE: null,
  BOOKING_CONFIRMATION_MEMBERSHIP_REMINDER: null,

  BOOKING_FORM_SERVICE_INFO_RENDERED: null,
  BOOKING_FORM_PHONE_FIELD_RENDERED: null,
  CUSTOMER_LOCATION_FIELDS_RENDERED: null,

  SERVICE_COST_CUSTOM_TEXT: null,
  SERVICE_COST_FREE: null,
  SERVICE_COST_COST: null,

  SERVICE_INFO_DESCRIPTION: null,
  SERVICE_INFO_EXPAND_LINK: null,
  SERVICE_INFO_IMAGE_URL: null,
  SERVICE_INFO_AVAILABILITY: null,
  SERVICE_INFO_MODIFY_LINK: null,
  SERVICE_INFO_SERVICE_NAME: null,
  SERVICE_INFO_SERVICE_COST: null,
  SERVICE_INFO_SERVICE_DEPOSIT: null,
  SERVICE_INFO_SERVICE_DURATION: null,
  SERVICE_INFO_PAY_LATER_LABEL: null,
  SERVICE_INFO_DEPOSIT_REQUIRED_LABEL: null,
  SERVICE_COST_AMOUNT_DETAILS: null,
  SERVICE_COST_SUBTOTAL: null,
  SERVICE_COST_TAX_LABEL: null,
  SERVICE_COST_TAX: null,
  SERVICE_COST_TAX_RATE: null,
  SERVICE_COST_TOTAL: null,

  CATEGORY_LINK: null,
  CATEGORY_LINK_UNDERLINE: null,

  CATEGORY_LIST_CONTAINER: null,
  CATEGORY_LIST_TOGGLE: null,

  EMPTY_CONTAINER_RENDERED: null,
  EMPTY_CONTAINER_BTN_ADD_SERVICES_RENDERED: null,

  AVAILABLE_TIME_SELECTION_BOOK_BUTTON: null,

  SINGLE_EVENT_DETAIL_IMAGE_URL: null,
  SINGLE_EVENT_DETAIL_RESOURCE: null,
  SINGLE_EVENT_DETAIL_LOCATION: null,
  SINGLE_EVENT_DETAIL_DESCRIPTION: null,
  SINGLE_EVENT_DETAIL_DATE_AND_TIME: null,
  SINGLE_EVENT_DETAIL_TIMEZONE: null,
  SINGLE_EVENT_DETAIL_BOOK_BUTTON: null,
  SINGLE_EVENT_DETAIL_SOCIAL_SHARE: null,

  SOCIAL_SHARING_FACEBOOK_ICON: null,
  SOCIAL_SHARING_TWITTER_ICON: null,
  SOCIAL_SHARING_WRAPPER: null
});
